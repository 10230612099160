@import 'styles/_variables.scss';

.logo{
    scale: 0.1;
    max-width: fit-content;
    
}
.banner{
    display : grid;
    margin-top: 300;
    width:100%;
    height: 55vh;
    background-color: $cor-secundaria;
    text-align: center;
    border-radius: 0 0 20px 20px;
    animation: aparecer 3s;
}
@keyframes aparecer{
    from {opacity: 0;
    }
}

.banner h1 {
    font-size: xx-large;
}

.banner p {
    font-size: small;
    font-weight: 100;
    font-style: italic;
}

.banner__heading {
    font-size: xx-big;
    color:black;
    position:absolute;
}

.banner img {

    filter: blur(1px) opacity(40%);
    width: 100%;
    height: 54vh;
    object-fit:cover;
    border-radius: 0 0 30px 30px;
}
