@import 'styles/_variables.scss';

.servicos{
    margin: $padding-vertical $padding-horizontal ;
    background-color: $cor-secundaria;
    border-radius: 20px;
    color: $cor-contraste;
    &__imagem img{
        max-width: 300px;
        padding: 20px 0;
    }
    &__titulo{
        font-size: xx-large;
        padding: 20px;
        text-align: center;        
    }
    &__texto{
        padding: 10px;
        display: grid;
        text-align: center;
        font-size: medium;        
        line-height: 130%;        
    }
    &__botao{
        margin: 12px;
        padding: 12px;
        text-align: center;
        font-size: medium;
        border-radius: 8px;               
    }
    &__badges{
        padding: 5%;
        display :flex;
        flex-wrap: wrap;
        gap: 20px;               
    }
    &__badge{
        background-color: azure;
        padding: 10px;
        text-align: center;
        color:darkcyan;
        display :flex;
        gap: 20px;               
    }            
}