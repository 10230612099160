@import 'styles/variables.scss';


.profissional{
    &__box1{
        margin: 30px 10px;
        text-align: center;
        display: grid;
        justify-content: center;
        align-content: center;    
      
    }
    &__box1 h1{
        font-size:x-large;
    }
    &__fotoMini{
        margin : 20px 0; 
        width : 169px;       
        border-radius: 10%;
        box-shadow: $sombra;
        cursor: pointer;
    }
    &__box1{
        display: grid;    
      
    }
    &__box2{
        display: grid;    
        font-size: small;
        font-style: italic;   
    }
  
}
// Resposividade
@media screen and (min-width: $break){	

    .profissional{
        display:flex;
        width:90%;
        margin: auto;

    
        &__box1{
            padding: 0 20px;
            border-right: 1px solid;
        }
        
        &__box2{
            line-height: 200%;
            padding: 0 20px;
            font-size: medium;
            font-style: italic;   
        }

    }   
}
