@import 'components/Content/Content.module.scss';

.atendimento{
    display: grid;       
    
    &__box1{
    display: grid;
    margin: 0 0 40px 0;
    }    

    &__box2{
    display: flex;
    flex-wrap: wrap;
    gap: 30px 10px;
    width: 100%;
}

    &__button{
        width: 100%;
        margin: 10px 0;
        display: flex;
        justify-content:center;
        
    }
    &__button button{
        background-color: green;       
        border-radius: 10px;
        color: white;
        font-size: x-large;
        width: fit-content;
        padding: 8px;
        cursor: pointer;

    }
    &__mini{
        width:30%;
        border-radius: 8px;
        margin: calc(0.5*$padding-horizontal);
        cursor: pointer;
        box-shadow: $sombra; 
    }
    &__badge{        
        border-radius: 4px;
        padding: 10px 10px;        
        cursor: pointer;
        font-size: medium;
        color: rgb(23, 80, 80);
        box-shadow: $sombra; 
    }
}    

@media screen and (min-width: $break){
    .atendimento{
        display: flex;

        &__box1{
        width:50%;
        }

        &__box2{
            width:50%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;
            width: 100%;
            }
        
        &__mini{
        width: 18%;
    }
    &__badge{        
        border-radius: 4px;
        padding: 10px 10px;        
        cursor: pointer;
        font-size: medium;
        box-shadow: $sombra; 
    }
}
}