@import 'styles/variables.scss';
@import 'components/Content/Content.module.scss';

.profissional{
display:grid;
font-size: large;
line-height: 150%;
margin: auto;
text-align: center;
}
.profissional__box1{
height: 10px;
}

  

// Resposividade
@media screen and (min-width: $break){	

    .profissional{
        width:90%;
        margin: auto;
        font-size: large;
        line-height: 150%;
        text-align: center;
    }    
}
